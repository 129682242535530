<template>
  <div>
    <div
      style="text-align: center;background: #566d96;font-size: 14px; color: yellow"
      v-if="oldTime"
    >当前存档时间：{{oldTime}}</div>
    <div style="padding: 5px 10px 10px 10px">
      <div style="display: flex;justify-content: between;align-items: center;
  border-bottom: 1px solid #ddd; argin-bottom: 7px;
    padding-bottom: 6px;
    ">
        <el-button
          @click="saveData"
          type="warning"
          size="mini"
        >存档</el-button>

        <div
          class="text-center"
          style="flex: 1"
        >
          <!-- 基于vue2实现一个select，选项取userList，当前值取user.id -->

          <el-select
            v-model="user.id"
            placeholder="请选择"
            style="width: 90px"
            size="mini"
          >
            <el-option
              v-for="item in userList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>

          <span style="font-size: 14px">
            {{total}}
            <span
              style="color:red"
              v-if="totalDiff"
            >{{totalDiff}}</span>
          </span>
        </div>

        <el-button
          @click="refresh"
          type="primary"
          size="mini"
        >刷新</el-button>
      </div>

      <div class="div-box">
        <div
          v-for="(item, index) in list"
          :key="index"
          class="d-flex"
        >
          <div style="width: 40%;">
            <img
              style="width: 100%;border-radius: 10px"
              :src="item.horizontal_image_url"
              alt
            />
          </div>
          <div style="width: 60%">
            <h2>{{item.title}}</h2>
            <span class="play_count_desc">
              {{item.play_count_desc}}
              <span style="color:red">{{item.diff}}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
// 引入dayjs
import dayjs from 'dayjs';
export default {
  data() {
    return {
      oldTotal: '0',
      oldTime: '',
      oldList: [],
      total: 0,
      list: [],
      user: {
        id: '9004871128',
        name: '穿山贾'
      },
      userList: [
        { id: '9004871128', name: '穿山贾' },
        {
          name: '知圣文华',
          id: '9000101721'
        },
        {
          name: '君羡历史',
          id: '9006032513'
        },
        {
          name: '鲸浪传媒',
          id: '9011539161'
        },
        {
          name: '阎颜言',
          id: '9001615331'
        },
        {
          name: '平西府文化',
          id: '9000160725'
        },
        {
          name: '竹公子',
          id: '9002997901'
        },
        {
          name: '一刻',
          id: '9000004047'
        },
        {
          name: '前进四放映室',
          id: '9001441150'
        },
        {
          name: '开轩话廊',
          id: '9005386900'
        },
        {
          name: '看穿电影',
          id: '9000158201'
        },
        {
          name: '蜗牛',
          id: '9005112335'
        }
      ]
    };
  },
  watch: {
    // 监听user.id的变化，如果变化了，则重新执行ready方法
    'user.id': function () {
      this.getData();
    }
  },
  computed: {
    // 设置totalDiff属性，计算快照的oldTotal和现在的total的差值
    totalDiff() {
      // 提取oldTotal和total中的数字，并进行计算，重新组成字符串
      let oldNum = String(this.oldTotal).replace(/[\u4e00-\u9fa5]/g, '');
      let newNum = String(this.total).replace(/[\u4e00-\u9fa5]/g, '');
      let diff = newNum - oldNum;
      // diff的值保留1位小数
      diff = diff.toFixed(1);
      if (diff) {
        if (this.total == diff + '万') {
          diff = 0;
        }
      }
      return diff > 0 ? '+' + diff + '万' : '';
    }
  },
  mounted() {
    this.ready();
  },
  methods: {
    ready() {
      // 判断是否有快照，有的话，则存入到oldList和oldTotal中
      let oldData = localStorage.getItem('data');
      if (oldData) {
        let { total, list } = JSON.parse(oldData);
        this.oldTotal = total;
        this.oldList = list;
      }

      // 读取缓存中的time，赋值给this.oldTime
      let oldTime = localStorage.getItem('time');
      this.oldTime = oldTime || '';

      this.getData();
    },
    refresh() {
      this.getData();
      // element的提示
      this.$notify({
        title: 'OK',
        message: '刷新成功',
        duration: 1000,
        position: 'bottom-right'
      });
    },

    saveData() {
      if (this.user.id !== '9004871128') {
        // 提示 请先切换回穿山贾
        this.$notify({
          title: '提示',
          message: '请先切换回穿山贾',
          duration: 1500,
          position: 'bottom-right'
        });
        return;
      }
      let data = {
        total: this.total,
        list: this.list
      };
      localStorage.setItem('data', JSON.stringify(data));
      // 用dayjs把当前时间存到localStorage中
      localStorage.setItem('time', dayjs().format('YYYY-MM-DD HH:mm'));
      this.$notify({
        title: 'OK',
        message: '保存成功',
        duration: 1500,
        position: 'bottom-right'
      });
      this.ready();
    },

    async getData() {
      let res = await axios.get(
        `https://pbaccess.video.qq.com/trpc.creator_center.header_page.personal_page/GetCoverList?vcuid=${this.user.id}&page_size=30&page=1`
      );

      let list = res.data.data.data.list;
      // list按照publish_date_desc排序
      list.sort((a, b) => {
        return new Date(b.publish_date_desc) - new Date(a.publish_date_desc);
      });
      this.getTotal(list);
      // 如果有oldList，则根据title进行比对，设置diff属性，diff的值为纯数字的差值
      if (this.oldList.length) {
        list.forEach(item => {
          this.oldList.forEach(oldItem => {
            // 判断oldItem.title中是否包含"《"，如果没有，则取：前面的字符串为标题
            let newTitle = item.title.includes('《') ? item.title.match(/《(.*)》/)[1] : item.title.split('：')[0];
            let oldTitle = oldItem.title.includes('《') ? oldItem.title.match(/《(.*)》/)[1] : oldItem.title.split('：')[0];

            if (newTitle === oldTitle) {
              let oldNum = String(oldItem.play_count_desc).replace(/[\u4e00-\u9fa5]/g, '');
              let newNum = String(item.play_count_desc).replace(/[\u4e00-\u9fa5]/g, '');
              console.log('oldNum :>> ', item.play_count_desc);
              let diff = newNum - oldNum;
              // diff的值保留1位小数
              diff = diff.toFixed(1);
              item.diff = diff > 0 ? '+' + diff + '万' : '';
              if (!item.play_count_desc.includes('万次')) {
                item.diff = '+' + (newNum - oldNum).toFixed(0);
                if (newNum - oldNum > 1000) {
                  // 按照0.x万的形式显示，比如旧值是3100，新值是 4200，则diff为4200-3100=1100，1100/10000=0.11，diff为0.11万
                  item.diff = '+' + (diff / 10000).toFixed(2) + '万';
                }
              }
            }
          });
        });
      }

      this.list = list;
    },
    getTotal(list) {
      let totalArr = [];
      list.forEach(item => {
        totalArr.push(item.play_count_desc);
      });

      // 把arr转换成数字数组，万转换成数字
      let numerArr = totalArr.map(item => {
        let num = item.replace(/[\u4e00-\u9fa5]/g, '');
        if (item.includes('万')) {
          num = num * 10000;
        } else {
          num = Number(num);
        }
        return num;
      });
      // numerArr求和，保留1位小数
      let total = numerArr.reduce((total, num) => {
        return total + num;
      }, 0);
      // total转为 '万'
      total = (total / 10000).toFixed(1) + '万';
      this.total = total;
    }
  }
};
</script>

<style lang="scss">
body {
  margin: 0;
}

h1 {
  font-size: 5vw;
  margin-top: 0;
}
.text-center {
  text-align: center;
}
.d-flex {
  display: flex;
  margin-bottom: 15px;
}
h2 {
  font-size: 4vw;
  padding: 0 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.play_count_desc {
  font-size: 3.5vw;
  color: #999;
  padding: 0 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media (min-width: 1024px) {
  body {
    margin: 0 15%;
  }
  h1 {
    font-size: 24px;
  }
  h2 {
    font-size: 16px;
  }
  .play_count_desc {
    font-size: 14px;
  }
  .div-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .d-flex {
      margin-bottom: 25px;
      width: 100%;
      flex: 1;
      width: calc((100% - 10px) / 3);
      min-width: calc((100% - 10px) / 3);
      max-width: calc((100% - 10px) / 3);
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
}
</style>